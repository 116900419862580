export default function GithubIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="11" height="11" rx="1" fill="white" />
      <path
        d="M6.51185 9.52864C6.6697 9.52864 6.76441 9.65492 6.76441 9.78121C6.76441 9.93906 6.63813 10.0338 6.51185 10.0338C6.354 10.0338 6.25929 9.90749 6.25929 9.78121C6.25929 9.65492 6.354 9.52864 6.51185 9.52864ZM10.6476 7.91854C10.4897 7.91854 10.395 7.79226 10.395 7.66598C10.395 7.5397 10.5213 7.41341 10.6476 7.41341C10.8054 7.41341 10.9002 7.5397 10.9002 7.66598C10.9002 7.79226 10.7739 7.91854 10.6476 7.91854ZM10.6476 6.84514C10.2056 6.84514 9.82676 7.22399 9.82676 7.66598C9.82676 7.76069 9.82676 7.8554 9.85833 7.91854L7.17483 9.33922C7.01698 9.11822 6.76441 8.99194 6.51185 8.99194C6.19614 8.99194 5.91201 9.18137 5.78573 9.43393L3.38636 8.17111C3.1338 8.04483 2.94438 7.60284 2.97595 7.22399C2.97595 7.03457 3.07066 6.87671 3.16537 6.81357C3.22851 6.782 3.32322 6.782 3.38636 6.81357H3.41793C4.04935 7.16085 6.16457 8.23425 6.22771 8.29739C6.354 8.36053 6.44871 8.3921 6.6697 8.26582L10.9949 6.02431C11.058 5.99274 11.1212 5.9296 11.1212 5.83489C11.1212 5.7086 10.9949 5.64546 10.9949 5.64546C10.7423 5.51918 10.3635 5.36133 10.0162 5.1719C9.22692 4.79306 8.34294 4.38264 7.93253 4.19322C7.58525 4.00379 7.33268 4.16165 7.26954 4.19322L7.17483 4.22479C5.40688 5.10876 3.03909 6.27687 2.91281 6.34001C2.66024 6.49787 2.53396 6.782 2.50239 7.12928C2.47082 7.69755 2.75495 8.29739 3.19694 8.51838L5.75416 9.84435C5.8173 10.2548 6.16457 10.5389 6.54342 10.5389C6.98541 10.5389 7.36426 10.1916 7.36426 9.74964L10.174 8.23425C10.3319 8.36053 10.4897 8.3921 10.6792 8.3921C11.1212 8.3921 11.5 8.01325 11.5 7.57127C11.4369 7.19242 11.0896 6.84514 10.6476 6.84514Z"
        fill="#111936"
      />
    </svg>
  );
}
