export default function LogoutIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0078 1.83008C9.45553 1.83008 9.00781 2.27779 9.00781 2.83008V9.22935C9.00781 9.78164 9.45553 10.2294 10.0078 10.2294C10.5601 10.2294 11.0078 9.78164 11.0078 9.22935V2.83008C11.0078 2.27779 10.5601 1.83008 10.0078 1.83008Z"
        fill="#111936"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.335 3.784C13.8184 3.43542 13.1545 3.66423 12.8823 4.18242C12.6028 4.71456 12.8222 5.3393 13.2748 5.66561C14.0671 6.23673 14.7001 7.00814 15.1052 7.90681C15.6191 9.04671 15.7367 10.3258 15.4393 11.5403C15.1419 12.7548 14.4465 13.8348 13.4641 14.6082C12.4817 15.3817 11.2686 15.8041 10.0183 15.8082C8.76791 15.8123 7.55215 15.3978 6.56469 14.6307C5.57723 13.8637 4.87488 12.7883 4.56954 11.5757C4.2642 10.3632 4.37345 9.0834 4.87986 7.94017C5.27911 7.03888 5.9071 6.26336 6.69559 5.68707C7.14608 5.35782 7.36137 4.73166 7.07844 4.20136C6.80291 3.68495 6.13752 3.46048 5.62322 3.81242C4.43984 4.62222 3.49936 5.74815 2.91409 7.06939C2.21325 8.65154 2.06207 10.4227 2.48463 12.1008C2.9072 13.7788 3.8792 15.2671 5.24577 16.3287C6.61235 17.3902 8.29488 17.9638 10.0253 17.9582C11.7557 17.9525 13.4345 17.3679 14.7941 16.2975C16.1537 15.2271 17.116 13.7324 17.5276 12.0517C17.9392 10.3709 17.7764 8.60076 17.0653 7.02322C16.4714 5.70582 15.5236 4.58606 14.335 3.784Z"
        fill="#111936"
      />
    </svg>
  );
}
