export default function GithubIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00061 1.5C3.96151 1.49877 1.5 3.95906 1.5 6.9957C1.5 9.39699 3.03983 11.4382 5.18428 12.1879C5.47308 12.2604 5.42883 12.0551 5.42883 11.915V10.9626C3.7612 11.158 3.69361 10.0545 3.58178 9.87012C3.35566 9.48425 2.82108 9.38593 2.98084 9.2016C3.36057 9.0062 3.74768 9.25075 4.19624 9.91314C4.52067 10.3936 5.15356 10.3125 5.4743 10.2327C5.54435 9.94386 5.69428 9.68579 5.90074 9.48548C4.17289 9.17579 3.45274 8.12138 3.45274 6.86789C3.45274 6.25958 3.65306 5.70042 4.04631 5.24941C3.79561 4.50592 4.06966 3.86934 4.10652 3.77472C4.82052 3.71081 5.56279 4.28595 5.62055 4.33142C6.02609 4.22204 6.48939 4.16428 7.00799 4.16428C7.52905 4.16428 7.99358 4.2245 8.4028 4.3351C8.54167 4.22942 9.22986 3.73539 9.89348 3.79561C9.92911 3.89024 10.197 4.51207 9.96107 5.24573C10.3592 5.69797 10.562 6.26204 10.562 6.87158C10.562 8.12753 9.83695 9.18316 8.10418 9.48793C8.25259 9.63389 8.37044 9.80796 8.45083 9.99997C8.53122 10.192 8.57255 10.3981 8.57239 10.6062V11.9888C8.58223 12.0994 8.57239 12.2087 8.75673 12.2087C10.9331 11.4751 12.5 9.41911 12.5 6.99693C12.5 3.95905 10.0373 1.5 7.00061 1.5Z"
        fill="white"
      />
    </svg>
  );
}
