import { SvgIcon, SvgIconProps } from "@mui/material";

export function SnsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 192 192" {...props}>
      <image
        id="image0"
        width="192"
        height="192"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADACAYAAABS3GwHAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAALEsAACxLAaU9
lqkAABz/SURBVHja7Z15kFXVnce/v/Neryz9umlWkX7NDio0uExMRW01Mmg0djJJFTWTxCaTqpip
JMJEE51JhiaJS0JlgFlqUklNiTpTU6lkSk3NJBiXbiNOErduEqUBQRoUEOgN6OX16/fub/44yz33
vkZNRLqB3+cPbfqdvve8e3/7+d1zAUEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BOFPgUZ7AucCDzY+msonJlYk8rxMESqQyNcmiGoUOEXgFBHSRAwwI0FcA2JKEDEQkCIwgYmIQeAO
BQYpdBAAQtCrFDoUo5cSQVuec8eTyLRdu2l172h/53MFUYA/kn9b1ZxGKeqTwFIG0glwHSlOExhE
DAUwiEmBQQArcsINIkB5PxP0z0oBxGClAgIYCoAiMCggBWKigBTABAYpJiL0KkIbEPQSB8+qBFrL
hoe2LxPF+KMRBXgHNjY0pxITSpYqBA1AUEfEdYo4RWAoghZoZiZiEDERtOASAv25VQoCYITYVw4i
Jj1Oj4cZb47PBLYeAkqBgADK/h3ApJgI7B2H20DoIMbjQLbtig2r20b7Go51RAFibFj1XH1SJa8B
5esVcb0CMSigUOjZCaEv4KFF18IcCrsRVOZQcLUS6M8JsD9bwVeApzCBO4YCPAUxSgTPqyhvTnps
BwFtoPzjJYni5sX3/tX+0b6+Y43zXgGaGlpT5WXDSwncQJRrVKAKa6UVgWHicyt8TiiNwGvLHUAR
aUFkK9gcegGlrTwBRnmiChUJiczfOaG2yubNIRRyhgpzCS/MCgBz/Nh8WwjB40rxo4vv/YIoA85j
BfjOp16sT6jg1kQCjcScisTpYXhhLS1sqBFaaT3WJbJWSEnH80RBVGiNgCZIewbiIKIAzmNQLHcw
HoKITX5glMLNTysL9LHNPBHmHTFF87xYG3NuM4J8y6IHvtQx2vdjtDivFKCpoTWVKM7doYA15Mfy
voDFBdWLx4kCuKSUCCBj1b2wRB+DtMUnduPjXiQMWaxHQBjW2PAHTEqFyXIilhgDvrewx2YmIAyH
RgjdEMtRCGhRirbM/fYXHhrte3SmOS8UoOlTL9YDiXUKwTUmlo7E8ERAwgolAUYgPYsfWlgYYU94
VtgJn2fpI94jtORhzmDyhUjoAzApkBZinVj7oQz5ibR/bE9h7e+BAAmKKoqn8FGP4K4DdyiilpKc
appxnoRI56wCrGloTU1QQSORuk1RUEeF1RO/ZOlXUtjF9i5pdRY6zAniguvidGdVvc9AREFE4HzP
U1A+tWEN6xKpYmZSWhkS+ngmSQ4VTlt5z7Kb/IDAsDmAU2ST2DulMnmEK9PqhP2hgLC+tuncDo/O
OQVY09CaKlfqDoVgDRFSIyePAKzFRrSqYxNf5VltF+PH8gA3rqDGb0IfrzJjzoERfufCo4iCgSLl
VBsKOSvPUUXWYxiwpVPPe1GYx5g5emsLpAXfz38UAmiFAwhoSSpumv4Pf/PsaN/bD4JzRgHWNLSm
SpG8Q5ERfC+8KEgCQ5cP5QkSmZp8xeQSTEmPQ2l5AsODOQz1D6P7zX7ODgw7obJ1ezVShYf8vMAb
x0GYZDvBs54pOl/Y83ghj63oREqfEQ8EFJUlUVo1HmUzKkFgDPf0IcgMYehwd7gmEVMsnW/oZN5V
lLz1CIApoagF+fz66U1faRnte306OScU4K6GP9yhGOtIcWWsDh4uKpmFKp3IMgAYQdafl4xL0GUr
p2H5jdNQUp4c8TyHdvZi13Nv4/Xnj7By8bm3mgtdHVry8dm05OOz3d/1dw3iV999HvnB7IhCHoZO
+rNx1eV89boVkXvTvPZn0dDGllKNZ5g4Zwpm3nAxyi+oQrK0qGDu+UwWJ3ccwLFn2pDrOQlFploE
1kKvAGIzH+WHUV7oqL3iQ0FueP30prUdo33fTwfJ93+I0WPtLe3XJFV+C8BpHR9oCAS4f5o4GABM
wsogEw8DYOI5l6fopttnn1LwLTMWpjBjYQq1l1bTs/++E7mBYR1OAGAOTNbA3rk14yaV4eKb52L7
T3eEv9TlUj1XZrArnxJMISeK+YzMWXQYw8TMqL11OaZdteAd554oLUZq+Vykls9F5zNt6HzmFSJz
WAUzZRv0OANBbFIONmtuAHNjIplo7Lz3n7YMD+fPekVQoz2BP4U1K1vTa295tVlRvoWBtJZlY+FB
DLC9j0xgYth4lljfZyOgBMy7opI+8bfz31X4fdLLq7HiKxcDRnSZQAQjnADCH0LmXZ/G5PmTQpfL
oVbqsMgE52ACERccgECsvQ6BzXmB9yT8caqvq0PVhy8CmEOZ96dlLx8zgVmvU4O8GQPM3FhSpJ7p
uXfzHWfinn9QnHUe4Ks3t6+DCtYQkNI3zsg0CKxjbGuptHmNySKxiYXAVFFdgus+Oyvy+YnOIbRu
Pcw7nzuKbP8wSscnaPbyKlzxyRpMqC5x46YvTOGiFTNpx5Nvwrf4xoKPOPfLb1uCp+57DvnB4XCo
UdwATAn7Dz5FaMoAiMDQawFTLqul6THhP7n3CA4/9XsMHu5GkBnC+JmTuPrKhVR56ZzIuEnXLcOJ
1t3gTNZZd7KTAuv4kUFk8gIiArOtbrnp1AK8qee+jWuQxbWVZ6E3OGs8wJdvbq//6i3t+whYB6YU
s7aDABshZ+igArYxJ4YOTazFBoBZiyegYnIo1Cc6h/DT776Gtq2HkOkfJiamoYEcdm07yo/dvx1D
A7nIEWuWTy6YZ8Csl2FHoHxSGRbfNM+ZWWZ2noCIwDqA8kxtSPhLI5XMmHJ5OjKm86U3sOtHT+Hk
G0eQH8wCIAwc7KI3f7YNR5/eHhmbKC1G5ZUXwXdaLgoCtHfwXSVb5YjoN5v/plUx9p184Acbe5o2
ps6kXLxfxrwCrKlvTX3llt0bCdQMRtpGGmSsO/nhBrGWEy1YfpgRDvHSg/mXV0U+2/NSN04cy9gI
wAkDg+lkZwa7th2JjJ++IIWScUURN6MdAI9swQHMvb4W1fMnkR1MNi5jG76NDDsZNYcmUMWcKZEx
R7ftYl+hfM909OlW5DPZyPjS6ZPsrAETX5lcg9mkKMxs9C38TkQjaSiYmdYUlXLryfs31J9+Sfhg
GNMh0O0r29NZpR5VgV7ICs2VDXNMdkaeVDhv4I2zPxKTyz7BKClPRM53bH+/0ZAAoUE0NXcC/eGJ
g+g60BdpXrN6FsoEFeQAvW+dRGrmBPfvSz+3FM33PYtcJgcGXEUIRm5H1B5y34cBUGnVuIIh/Yd6
whDQHIyYGUp/6QOPPE0lVePdIl2QybJLgs21YWLA9CmZMBLMMIuEzkmQTZDheqKsLeK0ItU8uOEH
TWV3fW39aMvQuzFmFeCLN+29gyjfBOaU6R4LI093G/RvzPU3IZEVe726yewrCGz4QUTEpeMSEVkr
KU+GNx0gky8QU0DExCe7MrR722Csa9Ml3nooAornAAfbjmCgcwAz6qYC0KHQwo8twGv//VpkHMOl
mgU6QOEAOlWOUVJZTtnefuvlzIoXjKgyDXQcwWDH2/7CGimdU9iQBy7y1/8zFiPQ89PeANox6DKs
XjzQBoB1mKSve4CmzPc3NHI+d23ZPfd0jLY8nYoxFwI11remvrhyz4PE+U1gnejqXjRd6oEpSED/
S//S5QIc+gItuzrKMKbLpAlkBfz4sWhIsHzldJSMS0YqqMwm7LBdBWxOxCbesmEWkc4vThHEvPjw
qzzs5RBzrqvFpHlV1qq6cmph2u5hVRvEme5+5HQy7Zh61SJ/bBgCxryKC7XY5CGwl9MUzqyp0WEP
27oZKfLjIDbewRoguK9ibw4jnUgkW7Pf/37jGRGeP4ExpQCNK9vTxaUTWkHcGP2ETNCv7wSbYrsp
ixNIeXfR5pe2K8waVCfHrkZ6YMfxyFkmTi7BZ+5biorJZWyFhLzDhjkfuwqOLrHGrHK8DMqM7ECW
Xnz495FfL/tsHYrKi6wD01/KS4yjxwh/ZwpZ3H+oJzJk6kcWYMYNl6A4NV5P3fnCUODJKGp8nmTc
pPYCvlLoHMBeSqPztuuaw3zAFiGs57AteZwC8YPDGzasO4Oi9J4ZMwrw1yv23Zbk4lYAaWYX79ty
dyh+toqp7xPbNADwEmItnuQqpMZqAybXYx0d/OHZThw/NhSZx8TqEqzetJw++fcX0eKrp/KkmnIT
l9u/N3lubG1BT8iWMWPyq0WFD28/gkPbw0S6fFI55t80Xwdv7MJ8HtELkEs5bBRIbz35WoGizPjo
Esz/4kdR8+kPo2LRhUiUFhl9J+ctbanWnMspiMkY4AX17Fe02ISOfnEgzMzIlqM9U+FqvSDipuEN
32sdvP/+9GjLmk/i/R/i/fP5FfvWEQWbFKHUVnhMkklKL9e75NCtTmqr5H5vlvWNJbNtzWSfqoJd
RFXGmxOB8rkAe1/q5XmXV1J8IWzi5FLMvnQSXXzddCy8aipmLKqgZJFCdjCP4YGcO56bq01SCZi2
oBJTF4QVpmO7utC5uxsA09EdxzD7qhokirTtqaytRPeeLmR6BtzxisuLuaZ+TkQJOp7YYR5/tG4P
NNTTR9mefq66eGZkbKKsGGUzKlG5NI0p9ZegYtGFVDylgpAPkOvtA0zsbmyMmT+7tTzdy6QFnXTC
ayIegn8viEJHYrppmYxHDB/IiYRfU4mo4e+uu/7xe59+une05Q4YAwrw+RX7NjL4G2R6AmxTFxGZ
BBOhazbJsPnMKQmRq1LExoJ0sxu5ZjfAH8sYGsjRnpe7MTSQR8WUkhFXhEvKk6icXo7aS6txyYqZ
mFBdSod39SIYDlzRxyqbImDqwpgCvN6Nzte7dRSRDxDk8pi6OFxDqJo3iQ7+9i0OcoFtaKOa+rmR
OXQ8scNEgWbFW+nkduBQD3W+tA/JsiKMm1E54jVOTihD+azJuhXi0nkIMlkMvd0NcECm70dfU51f
kSKYJNctaZBr0TAKGL/e7rlohnmIxymqHWMNTypBdNu3Vlz/u+88+XTHaMvfqCtA3ew7M0pxA4BS
613Jb14zMY8t3YWlzoj19/9vHwm0Qq+vPdnnY125zv3d0EAOB3eeQNvWt/ngzuNuDaA8VTzinCfN
Go85fzYVB1o7eTiTI3D4EDwRYer8mALs7sGx3d26QQ+Mnn29mLxgEsonlQMAisqLoIoVdbYfgVaA
IhQqwGsRbwdb5FFM+cEsju94C50v7UXmsM4LiiaWQyULb2+itBgTFtcAAAY73jadpOQ9aGP6g8iW
Z8Nrb6+l3rIFZLWRbJXZOgUbYkXvi5dwoAyM49958qmtoy1/o64AbW9s3L903pqfKEIDgVPOyhsL
bsJQ02IMgm4j9tqX4aTBhUnKunKd7GrrFgbtvgv3FQfEdKIzg30vd+PV5sNof+4oug70ITuQQ3XN
+Mi8i8uTqJo1nvY8/zZsCGQFc1rcA+zuwrHd3RGB6Hq9C7M+dGEYCqWr0L2nE5nuARSXF3FN/dx4
CATbM2RCOZsPsTLnDTI5ZA53o3f7fj7261fpRPubPNR5AqooQcWV0fmX104HD2WRefMomW44be21
oYBtvDPX0xRHSYc41gZR2J9nQiZSypaZ497B5ggAgPVFX7/77tGWPWCMJMFbttZ25DF0LZFq03Kq
2K3qArYQp2v6ZtUScJUfBvkrr7b5U68LmMjKNK3ZjkbyKhdu2TcicMTEfZ1DvHPbETT/eDf/x9de
QNeBvsi8py+sxPRFleymZkuCp1rPdSLAGOwewK5f7Ip8vOQzy81s36lNnW0NlvzlY9cEaOwxMzBw
sJs6n99Be3+0FfsfeQbDvdH5V1273BzSW/v25ugucGDOZXtW7TWy98ZbGWajKIoUG5fAbJaJQdTL
HKwuuusbTWdEsN4DY0IBAGDL1kUdP/7l7GUE1QTTxKkvcFhVUJE+58CuwNiuCA2pSGXIlPGdM3eW
y9byPVEjUxEheJpnSvsnOzP4+QOF/UBVM8eTXSPgU0q+K+DDZsrMwBvNb6Dr9S43qqyqHHNuXEAj
thqwVuhQPl2AaJPVsPbvr0+Yq9fX/iYf/Nm26M0vLUZRpV6hdpWg8ELa/7PtwWY7ERPeeCcg05Nl
Lj3r9nA2i2c6Ge5IQtUVf/2eLWdWst6ZMaMAlh9tnbOemFeD0AsAoMCreuoftMCZsoQuIXqmPCC/
LBq3yKHbCJvibEHfLawZN+8WwIxLz/bnueOVzsh8K2eFLQmensVgFJ5Ha0zrI62RBa25Ny7C+Asq
Co/gmq7tene8Iw3eQh8TlJ/r6Np//77DCGL9QMnK8TzCPL3qjd2pAtF4NPo1bYnVNl+YJRunIg8l
+geX0Z137v/ABeiPZEy2Qvxw6/wtt69sb4FSzQClTUnNGTkdhLqwx8WufjsOYGvefpcju9tCAK7/
3CyUlifd2sJb7cex49dHjcAE+tjmeGx6I/o6M5G5Oj2BFx2M5Ai85S5brgWYBrsHsPsXu7D4Ly52
Qxd9cskIf248gIm5k2XFqP34Mq0T+nvS0W07MXioJ/SQrm85cIuA+UyWVOnIyb1b3VMIy/rWk7q2
oLAdIrwAnv2xF03nKz0EXl981zc2j6Y8vRNjUgEA4IdbF3UAqP3yze1NAOlVRBtpc7SyYAwfhw9y
G22wBjnm1skcoGJyKeZdlnLnnJIux85fH2OwFZ4AZnXTeYoZC1MR69x9oM/IiulCJQYV2O9QGWEX
moxQAsC+5jcwdcl0TJqnuzNLq8oLD+Ae6QwFbcrltZEh2Z4+DB7qMYm+MvN3yoaiqvFUlIomw7me
PlPgNNGg8r6OeU7ZrkuahTBz3LDlwbYwuTUvAMTcVpygBrrzrjFn9X3GXAgU51/+Z1ETKFEPRgdc
OyfbTBiAs+4uweVoeGqSYdis2vyW+UhHf+Rck2eNQ92N02Liy7YpDpd+ooamL0xFPj3Q2um1ntrV
1vfwxVwYoVdjtz/ySkFvT3QafmsPcW4gi+N7j0aGTP3IQhRXldNIPT4gYNrHroiMHzrcheHek0Zh
9DMGHGil8Ve8bClaXz3tGaKtId6iGIAgwOaSwf5rx2LIE2fUy6DvhRd2//P+Ky/6wkPgRCnAH7JB
uV0AgzNf4cqxUmFp0sSvrrJnFxiO7R/AshumIFkU2oH0kkpMrC5FdiCH7GAe4yqKUV0zji5rmIUl
fz4zMq/XnnwTHS8cida7CRQvg3bu7sGx3V1huRTw9w7S/mAwB87lqXrx1BGvwf4n2tlbA7BlXq66
+AKnsKoogcqLLkQ+M6ytcS6P5IQyjK+digtXXYNxtdMixzzy0xbO9Z40XtN7lsir6ZvdI/TKuy15
KrvWQkys1wyM3+gAgk9MuOeuH65vacngLGDMhkBxNj22rBfA2jUN7ZsRcDMz1xAxmIkVBSb/VKyT
YN2uC9uebGuLCuGjAkycGcjRMw8fwI23z46ca/HVU7D46invOJ/uA3343X+9HrrQsMg6IsqGUrqw
ZB7NNNUuApgC2teyF1OWTEfVvOqCv9dWll3lCwQcfXEvTZwzGVMuC0Oh4srxqPn0h9/1enY1t2Jg
3yHXPsV6jtHnKUyLs84fmEi5aMimvbbVp5egNk28Z+36MyoUp4ExHwLF2fTYoo6NP19cS0RrGdTh
WpY5LH3azFR51VEABR2VAPDas8f4sX/cjROdQ+95Dvtf6cT/fq/VlNvDh+7NI5ojhkD2fATi8AF4
wK/GEohf/c+XRw6FiO0T/RR2qhLe+MlvcfDJV99L0AUACDJZHP3F79D19CsuKbGt2GSeRlBEYcHV
hUAURlW2/qZ/bOEsllWchcIPnEUeIM4Pfr5485qG1scVJ9aB0AjyVo9MMhp9ssRri4b52DxBsPel
Hu460I+ZiybQ8pUzMLmmMAkdGsih+0A/Xn5sHw7v6gl7YWJWn0DR09pz2+dsSD8Ar8wCki4umW1R
AAx2D2DPL9uxMFYJcg7GqJzSHgQA6K1f/Z66XtrLM264hCbOmYriysKnxYJMFj2v7EHP/72KfG+f
Vz3yr5B7sIWi30fXHcxWi7bIuZ8CXl35rbUtoy0L7wd6/4cYfe5uaE0DqkkRbou/jsj16MDbPxMA
ENvM1tvvs2xcEhOri1E6PgEwo68rg/6uQSi2/T6nekmG3R493PPzVDvTnWr3N/KOG992EdB7g0K5
t8y43ezcZmBgTpYV0bgLKm08z9nuE5Tv7QPI/H3kevgb+ZonxQhMHJBSxOB85N0DCUIPI/ftyd9c
u2m07/vp4JxQAMvdDa31iQStI3C97pGJbYIb7vIcvpjOVwArbAg3xHVbKIbHCztWEd95zt9KUAuj
PkZAcSWzSXDBO8PcnqWhcCZiWxb6AknsHzewrcnhnqYAItspxrZSVN53ALHu5WFzLruVoz52LxE2
D+Rzm2qb1vaO9r0+XZxTCmD55qda64mxThHXRzeGdS+bgNctGvEAynvDy6mE3I2F3VI9PF5kHMJx
sO8IsB7ACWFoee1u0P4eokrvzeB2sHaKYz/T54/ueh1+Z1YKRMzuOOHepnZH6EKv5DblNYKvFG3K
5LObzyXBt5yTCmBpamhNowjrFIJGf098Z/kotkenF274uyXrn+3msVY5vJdfmDHGQlM8rAitsren
p1nF8Heo1q9PRWTL9shO1GAmhYLwyA9nYu8bC+fhKbMLD+0u05E3Wbq/a0sQbxni8odqm87dt0+e
0wpgaVrVmk7m8+tIoR7gtBV+LxxCJCQwsbgaKVTxXoqhN5iNvmgjZoHd5rUjbNg7okKGljgwVt6U
P725hnmI/7fefG1lNfZ+g2h4FXhvsomEZS1MvL626Usto33fzgTnhQL43LvqhUbFuI2I6wvicmih
tu8PILstOdsQxnuNUfhiOvfwjlJxq+wlrt47xCJvf6SCN9boJ9niHss9YhjLU+xnbF92EdsJ2wp5
xBP5iTWgiHsR8CaVLN58Llv7kTjvFMBy/6rfpAHUJxXdAeY6/82MfoXID0VOncQ64YMfsjhhc57A
t8JeohvzDmH44r1sL55zeEm8ezeZE2wTkhlvEYZAgTtugriXgYcJw4/N++75Ye1H4rxVAJ/7V/0m
XaL4VhAaCEF95D2/XozuXmrB8YTTL4X61ZOo9fa9ga9gYV5RWJEqLJ/GxxWOiXoOr0xK6AXjIVL5
xzJ9yTZ5s7woQAEbV/0mDRXUJxVuJcrXEZAm8t4A47+WKPomxjBpDZ+vdeVLX1EK4vERa/J+jhCp
YJEfBvklznhlyShIC4GfBdByyQOrW0b7+o41RAHehX/9y+Y6JFBHim5NENLEvDTyXi+9QxT8RFj5
ybIX7vhJrP8OsmgyHL6PTJcuTT6AyJvm4Ydl1quAuEcRtifAbaD848hArPy7IArwR/JgY3Mqh1wd
oOqUwjWKOUWEOkVcYWPuU4Ymit1qsiulunDKVnwC8hNp39v47xcGc4dKcFuC0REQb8dwtuXKTas7
Rvv6nG2IApwmHmx8NJVMltaBVUpRUEfEqQSpGiL3Fvq0CWvStmqj38cVWZDrcCVQ5g5S6CVCrwJ3
ALn9gOotRdCWQab3WrHsp4X/B0Q0ZCznSbzNAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDI0LTAzLTIz
VDA1OjM5OjA4KzAwOjAwRajn0QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyNC0wMy0yM1QwNTozOTow
OCswMDowMDT1X20AAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjQtMDMtMjNUMDU6Mzk6MDgrMDA6
MDBj4H6yAAAAAElFTkSuQmCC"
      />
    </SvgIcon>
  );
}
