export default function MediumIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.06 1.5H1.94C1.69662 1.5 1.5 1.69662 1.5 1.94V12.06C1.5 12.3034 1.69662 12.5 1.94 12.5H12.06C12.3034 12.5 12.5 12.3034 12.5 12.06V1.94C12.5 1.69662 12.3034 1.5 12.06 1.5ZM10.52 4.32838L9.959 4.866C9.9095 4.90313 9.88612 4.96363 9.89575 5.02275V8.97863C9.88612 9.03913 9.9095 9.09963 9.959 9.13538L10.509 9.673V9.79262H7.748V9.6785L8.31588 9.12713C8.37225 9.07075 8.37225 9.05425 8.37225 8.97038V5.76938L6.791 9.77888H6.57788L4.73813 5.76938V8.4575C4.72163 8.57025 4.7615 8.68438 4.84125 8.7655L5.581 9.66063V9.78025H3.48V9.66063L4.21975 8.7655C4.2995 8.68438 4.33387 8.56888 4.316 8.4575V5.35C4.32562 5.26338 4.29262 5.1795 4.22662 5.12038L3.56938 4.32838V4.20875H5.61125L7.187 7.66688L8.57438 4.2115H10.52V4.32838Z"
        fill="white"
      />
    </svg>
  );
}
