export default function TwitterIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 4.07283C12.5866 4.25218 12.1494 4.37074 11.702 4.42483C12.174 4.14286 12.5277 3.69926 12.6975 3.17633C12.254 3.44036 11.7684 3.62639 11.262 3.72633C10.9235 3.35924 10.4727 3.11489 9.9804 3.0316C9.48806 2.9483 8.982 3.03078 8.54158 3.26608C8.10117 3.50139 7.75131 3.87622 7.54688 4.33179C7.34245 4.78736 7.29501 5.29789 7.412 5.78333C6.51518 5.73797 5.63794 5.50445 4.83726 5.09795C4.03658 4.69145 3.33037 4.12106 2.7645 3.42383C2.56603 3.77042 2.46174 4.16293 2.462 4.56233C2.4613 4.93324 2.55232 5.29857 2.72697 5.62579C2.90161 5.95301 3.15446 6.23197 3.463 6.43783C3.10439 6.42807 2.75344 6.33184 2.44 6.15733V6.18483C2.44269 6.70452 2.6248 7.20733 2.95552 7.60821C3.28625 8.00909 3.74529 8.28343 4.255 8.38483C4.05879 8.44454 3.85508 8.47602 3.65 8.47833C3.50804 8.47667 3.36643 8.4638 3.2265 8.43983C3.37165 8.88688 3.65254 9.27756 4.03009 9.55752C4.40764 9.83748 4.86307 9.99278 5.333 10.0018C4.53946 10.6262 3.55974 10.967 2.55 10.9698C2.36615 10.9704 2.18245 10.9594 2 10.9368C3.03094 11.6025 4.23235 11.9558 5.4595 11.9543C6.30633 11.9631 7.14643 11.8031 7.93071 11.4836C8.715 11.1641 9.42776 10.6915 10.0274 10.0934C10.627 9.49534 11.1014 8.78379 11.4229 8.00033C11.7444 7.21686 11.9066 6.37718 11.9 5.53033C11.9 5.43683 11.9 5.33783 11.9 5.23883C12.3316 4.91698 12.7038 4.52241 13 4.07283Z"
        fill="white"
      />
    </svg>
  );
}
